import Vue from 'vue';
import {beepSound} from '@/common/utils';
import moment from "moment";
import {phone, name} from '@/common/masking'
Vue.mixin({
  data() {
    return {
      modal: this.$bvModal,
      bvToast: this.$bvToast,
      regExps: {
        hex  : /^[0-9a-fA-F]+$/,
        pwd  : /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/, // 비밀번호 최소8자 영문,숫자,특수문자 조합
        hp   : /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/, // 휴대전화번호
        ip   : /(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}/, // 아이피주소
        num  : /^[0-9]+$/, // 숫자
        tel  : /^(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]))-(\d{3,4})-(\d{4})$/, // 일반 전화번호
        url  : /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
      }

    }
  },
  methods: {
    makeRandom(min, max){
      let RandVal = Math.floor(Math.random()*(max-min+1)) + min;
      return RandVal;
    },
    async getDownload(fileName, result){
      try{
        const fileLink = document.createElement('a')
        fileLink.href = window.URL.createObjectURL(new Blob([result.data]))
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      }catch(err){
        console.log('getDownload---err--->', err);
      }
    },
    async alertTest() {
      let r = null;
      try{

        await beepSound('danger');

        await this.modal.msgBoxOk( '메시지', {
          title: '[TEST] Warning' ,
          headerBgVariant: 'warning',
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          okVariant: 'warning',
          centered: true, size: 'sm'
        });
        return r;
      }catch(err){
        console.log("# alertWarn Error ---> ",err);
      }
    },
    async alertModal(msg, title='Information', variant='info'){
      try{
        await beepSound('success');
        await this.modal.msgBoxOk( msg, {
          title: title,
          buttonSize: 'sm',
          headerBgVariant: variant,
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          okVariant: 'primary',
          centered: true, size: 'sm'
        });
      }catch(err){
        console.log("# alertAsync Error ---> ",err);
      }
    },
    async alertWarn(msg, title=null){
      let r = null;
      try{
        await beepSound('warning');
        r = await this.modal.msgBoxOk( msg, {
          title: title? title:'[알림] Warning' ,
          headerBgVariant: 'warning',
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          okVariant: 'warning',
          centered: true, size: 'sm'
        });
        return r;
      }catch(err){
        console.log("# alertWarn Error ---> ",err);
      }
    },
   async alertDanger(msg, code=999){
      let r = null;
      try{
        await beepSound('danger');

        r = await this.modal.msgBoxOk( msg, {
          title: `[${code}] Error ` ,
          headerBgVariant: 'danger',
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          okVariant: 'danger',
          centered: true,
          size: 'sm'
        });
        return r;
      }catch(err){
        console.log("# alertDanger Error ---> ",err);
      }
    },
    async alertError(err){
      try{
        console.log(err);
        return await this.alertDanger(err.message, err.status?err.status:'E999');
      }catch(err){
        console.log("# alertError ---> ",err);
      }
    },

    async alertSuccess(msg, title=null){
      let r = null;
      try{
        await beepSound('success');
        r = await this.modal.msgBoxOk( msg, {
          title: (title)? title:'[알림] 완료' ,
          headerBgVariant: 'success',
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          okVariant: 'info',
          centered: true,
          size: 'sm'
        });
        return r;
      }catch(err){
        console.log("# alertSuccess Error ---> ",err);
      }
    },

    async confirmModal (msg, title=null){
      let r = null;
      try{
        await beepSound('danger');
        r = await this.modal.msgBoxConfirm( msg, {
          title: (!title)?'[확인] Confirm':title,
          buttonSize: 'sm',
          headerBgVariant: 'warning',
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,
          okVariant: 'danger',
          centered: true, size: 'sm'
        });
        return r;
      }catch(err){
        console.log("# confirmModal Error ---> ",err);
      }
    },
    async toastInfo(msg, title='INFORMATION', type='info'){
      try{

        await this.bvToast.toast( msg, {
          title: title,
          variant: type,
          autoHideDelay: 3000,
          appendToast: true
        });
        await beepSound(type);
      }catch(err){
        console.log(err);
      }
    },
    toastWarn(msg){
        this.bvToast.toast( msg, {
          title: 'WARNING',
          variant: 'warning',
          autoHideDelay: 3000,
          appendToast: true
        });
        beepSound('warning' );
    },
    async toastResult(result, message='요청'){
      try{
        let type = 'warning';
        let title = `${message} 결과`;
        let mesg, speechMsg;
        if(!result){
          type = 'danger';
          mesg = `[${message} 에러] 응답 없음`;
        }else if(result.code===200){
          type = 'primary';
          mesg = `[${message} 성공] ${result.message}`;
          speechMsg = `${message} 완료`;
        }else{
          mesg = `[${message} 실패] ${result.message}`;
          speechMsg = `${message} 실패! 메시지를 확인하시기 바랍니다.`;
        }

        await beepSound(type);

        await this.bvToast.toast( mesg, {
          title: title,
          variant: type,
          autoHideDelay: 2000,
          appendToast: true
        })
        // await speech(speechMsg);
      }catch(err){
        console.log(err);
      }
    },
    toastError(err){
      this.bvToast.toast(
        err.message,
        {
          title: 'ERROR',
          variant: 'danger',
          autoHideDelay: 3000,
          appendToast: true,
          toaster: 'b-toaster-top-left',
        })
    },
    toDateFromStr(v){
      return v.slice(0,8).replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
    },

    toHumanTime(temp=0){
      temp = temp*60;

      let years = Math.floor(temp / 31536000);
      if (years) {
        return years + ' 년';
      }
      //TODO: Months! Maybe weeks?
      let days = Math.floor((temp %= 31536000) / 86400);
      if (days) {
        return days + ' 일';
      }
      let hours = Math.floor((temp %= 86400) / 3600);
      if (hours) {
        return hours + ' 시간';
      }
      let minutes = Math.floor((temp %= 3600) / 60);
      if (minutes) {
        return minutes + ' 분';
      }
      let seconds = temp % 60;
      if (seconds) {
        return seconds + ' 초';
      }
      return '1초 미만'; //'just now' //or other string you like;
    },
    toHumanSize(bytes, si=true){
      let thresh = si ? 1000 : 1024;
      if(Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }
      let units = si
        ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
        : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
      let u = -1;
      do {
        bytes /= thresh;
        ++u;
      } while(Math.abs(bytes) >= thresh && u < units.length - 1);
      return bytes.toFixed(1)+' '+units[u];
    },
    /**
     * YYYYMMDDHHmmss --> HH:mm:ss
      * @param v
     * @returns {*|string}
     */
    toTimeStr(v){
      return v? v.slice(-6).match(/\d{2}/g).join(":"):'';
    },
    toLocalTime(v){
      // console.log(val);
      return v?moment(v).format("YYYY-MM-DD HH:mm"):''
    },
    toLocalTimeSec(v){
      // console.log(val);
      return v?moment(v).format("YY/MM/DD HH:mm:ss"):''
    },
    toIsoDate(v){
      return v? moment(v, 'YYYY-MM-DD HH:mm').toISOString(): ''
    },
    isoToYmd(v){
      return v? moment(v).format('YYYY-MM-DD'): ''
    },
    ymdToIso(v){
      console.log( 'ymdToIso()--->', v );
      return v? moment(v, 'YYYY-MM-DD').toISOString(): ''
    },
    toPhoneTxt(v){
      return v? v.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`):''
    },
    toPhoneMask(v){
      return phone(this.toPhoneTxt(v))
    },
    toNameMask(v){
      return v? name(v):''
    },
    toPhoneVal(v){
      return v? v.replace(/[^0-9]/g, ''):''
    },
    toComma(n){
      if(!n) return 0;
      let reg = /(^[+-]?\d+)(\d{3})/;   // 정규식
      n += '';                          // 숫자를 문자열로 변환
      while (reg.test(n)) {
        n = n.replace(reg, '$1' + ',' + '$2');
      }
      return n;
    },
    toCommaInt(n){
      if(!n) return 0;
      return this.toComma(n.toFixed(0));
    },
    isValidExp(val, exp, len=0) {
      let ret = true;
      if(len && val.length !== len) {
        ret = false;
      }
      return this.regExps[exp].test(val) && ret;
    },
    getCodeMaps(group){
      // console.log(this.$store.state.codeMaps[group]);
      return this.$store.state.codeMaps[group]?this.$store.state.codeMaps[group]:{};
    },
    getCodeOpts(group){
      // console.log(this.$store.state.codeOpts[group]);
      return this.$store.state.codeOpts[group]?this.$store.state.codeOpts[group]:[];
    }
  }
})
