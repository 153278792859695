/**
 * 원클릭견적(하우머치) 타입 정의 공통타입
 */

let type = {};

type.payType = {
    'CASH': '현금',
    'CARD': '카드',
}

type.status =  Object.freeze({
    WAITING    : '견적대기',
    PROGRESS   : '견적진행',
    SUCCESS    : '견적완료',
    CANCEL     : '견적보류',
})

type.statusClass = Object.freeze({
    PROGRESS: 'fc-howcost',
    SUCCESS: 'fc-green',
    CANCEL: 'fc-g'
})

module.exports = type;