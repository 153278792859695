<template>

  <div class="wrapper">
    <div class="animated fadeIn">

      <BCard>
        <BCard class="mb-1" footer-tag="footer" header-tag="header">

          <div slot="header" class="fs-12">
            <BIconCreditCard2FrontFill/>
            <strong> 쿠폰관리 </strong>
            <div class="card-header-actions">
              <small class="text-muted">쿠폰을 생성/관리 합니다.</small>
            </div>
          </div>

          <BRow class="mb-2">
            <BCol cols="3">
              <BInputGroup>
                <BInputGroupAppend>
                  <BButton size="sm" variant="warning" @click="(createViewVisible = !createViewVisible)">
                    <BIconPlusCircleFill/>
                    쿠폰생성
                  </BButton>
                </BInputGroupAppend>
              </BInputGroup>
            </BCol>

            <BCol>
              <BInputGroup>
                <BInputGroupAppend>
                  <BFormSelect v-model="searchField"
                               :options="searchOpts"
                               size="sm"/>
                </BInputGroupAppend>
                <BInputGroupAppend style="width: 300px;">
                  <BFormInput v-model="searchWord"
                              v-b-tooltip.hover
                              size="sm"
                              title="Enter 검색" type="text"
                              @keyup.enter="getCouponList"/>
                </BInputGroupAppend>
                <BInputGroupAppend>
                  <BButton size="sm" variant="primary"
                           @click="getCouponList">검 색</BButton>
                </BInputGroupAppend>
              </BInputGroup>
            </BCol>

            <BCol cols="2" class="text-right">
              <BButtonGroup class="mr-1"  style="min-width: 58px;">
                <BFormSelect v-model="perPage" :options="[10,20,30,50,100,200]" size="sm" @change="getCouponList"/>
              </BButtonGroup>
              <BButtonGroup>
                <BButton size="sm" variant="primary" @click="getCouponList">
                  <BIconArrowRepeat/>
                  Refresh
                </BButton>
                <BButton class="ml-1" size="sm" variant="info" @click="exportExcel">
                  <BIconFileSpreadsheetFill/>
                  다운로드
                </BButton>
              </BButtonGroup>
            </BCol>
          </BRow>

          <BRow>
            <BCol>
              <vue-excel-editor
                  ref="excelGrid"
                  v-model="rows"
                  :localized-label="$localizedLabel"
                  :page="perPage"
                  :readonly-style="{backgroundColor:'#EFE'}"
                  class="mb-1"
                  @setting="onColumnResize"
                  @select="selectCouponRow"
                  @update="updateCouponField">
                <vue-excel-column field="idx" key-field label="고유번호" type="string" width="100px" readonly invisible />
                <vue-excel-column field="name" label="쿠폰명" type="string" width="240px" />
                <vue-excel-column field="gift_code" label="쿠폰코드" type="string" width="180px" readonly />
                <vue-excel-column field="use_type" label="쿠폰타입" type="select" width="120px" :options="useTypeValues" />
                <vue-excel-column field="prd_codes" label="지급 라이센스" width="150px" />
                <vue-excel-column field="expiration_date" label="쿠폰만료일" width="150px" />
                <vue-excel-column field="applicable_days" label="라이센스 기간(일)" width="140px" />
                <vue-excel-column field="available_count" label="사용가능횟수" width="100px" />
                <vue-excel-column field="used_yn" label="사용완료" type="select" width="80px" :options="['Y', 'N']" />
                <vue-excel-column field="create_date" label="쿠폰 생성일자" width="150px" readonly />
                <vue-excel-column field="memo" label="비고" width="200px" />
              </vue-excel-editor>
            </BCol>
          </BRow>

          <BRow>
            <BCol>
              <BButtonGroup>
                <BButton variant="danger" @click="deleteCoupon">
                  <BIconTrashFill/>
                  선택 쿠폰삭제
                </BButton>
              </BButtonGroup>
            </BCol>

            <BCol>
              <BProgress :max="totalCount" :value="progCount" height="2rem" show-value variant="warning"/>
            </BCol>
          </BRow>
        </BCard>


        <!--    쿠폰 생성    -->
        <BForm @submit="createCoupon" v-if="createViewVisible">
          <BCard class="mt-2">
            <div slot="header">
              <BIconArchiveFill/> <strong> 쿠폰생성 <BBadge class="ml-3" variant="dark">Coupon Create..</BBadge> </strong>
              <div class="card-header-actions">
                <small class="text-muted">판매 상품 상세내용</small>
              </div>
            </div>

            <BRow>
              <BCol sm="8">
                <BTable small stacked :items="[cou]" :fields="createCouponFields">
                  <!--                  <BFormRow size="sm" v-model="cou.name"></BFormRow>-->
                  <template #cell(name)>
                    <BFormInput size="sm" v-model="cou.name" max="40"></BFormInput>
                  </template>

                  <template #cell(fun_type)>
                    <BFormSelect size="sm" v-model="cou.fun_type" :options="funTypeOpt"></BFormSelect>
                  </template>

                  <template #cell(use_type)>
                    <div style="display: flex;">
                      <BFormSelect size="sm" v-model="cou.use_type" :options="useTypeOpt"></BFormSelect>
                      <Tooltip :text="tooltipText">
                        <BIconQuestionCircleFill />
                      </Tooltip>
                    </div>
                  </template>

                  <template #cell(prd_codes)>
                    <BInputGroup>
                      <BFormSelect size="sm" v-model="prdCodeSelected" :options="prdOpts" :state="prdSelectedStats" @change="selectedPrd"/>
                    </BInputGroup>
                    <BTableSimple class="mt-2 mb-1 small">
                      <BTr v-for="(productInfo, index) in cou.prd_selected_infos" :key="index">
                        <BTd>{{productInfo.prd_code}}</BTd>
                        <BTd>{{productInfo.prd_name}}</BTd>
                        <BTd>{{productInfo.disp_name}}</BTd>
                        <BTd class="text-right">
                          <img src="../../../assets/svg/icon-cross.svg" alt="라이센스 삭제" class="icon-row-btn" @click="deletedPrd(productInfo.prd_code)">
                        </BTd>
                      </BTr>
                    </BTableSimple>
                  </template>

                  <template #cell(applicable_days)>
                    <BFormInput size="sm" v-model="cou.applicable_days" type="number" min="1" max="9999" :state="applicableDaysStats"></BFormInput>
                    <span class="fc-howcost">(최대 9999일 까지)</span>
                  </template>

                  <template #cell(expiration_date)>
                    <BFormDatepicker size="sm" v-model="cou.expiration_date"></BFormDatepicker>
                    <span class="fc-howcost">(만료시간 00:00:00 고정)</span>
                  </template>

                  <template #cell(create_count)>
                    <BFormInput size="sm" v-model="cou.create_count" type="number" min="1" max="10000" :state="createCountStats"></BFormInput>
                  </template>

                  <template #cell(available_count)>
                    <BFormInput size="sm" v-model="cou.available_count" type="number" min="1" max="9999999999" :state="usedCountStats"></BFormInput>
                  </template>

                  <template #cell(used_yn)>
                    <BFormSelect size="sm" v-model="cou.used_yn"
                                 :options="[ {value:'N', text: 'N (사용가능)'},{value:'Y', text: 'Y (사용불가)'} ]"/>
                  </template>

                  <template #cell(memo)>
                    <BFormTextarea size="sm" v-model="cou.memo"></BFormTextarea>
                  </template>
                </BTable>
              </BCol>
            </BRow>

            <div slot="footer">
              <BButton size="lg" variant="howcost" type="submit">
                <strong>쿠폰생성</strong>
              </BButton>
            </div>
          </BCard>
        </BForm>
        <!--    /쿠폰 생성    -->

      </BCard>

    </div>
  </div>
</template>


<script>
import Tooltip from "@/xcost/home/components/Tooltip.vue";

import {
  alertSync,
  alertConfirm,
  apiCall,
  cloneVar,
  commify,
  toastSync,
  alertWarn,
  apiMultiPart, alertSuccess
} from '../../../common/utils';
import qs from 'querystring';
import moment from "moment";
import couponType from '../../../common/couponType';

const _cou = {
  idx: '',
  name: '',
  gift_code: '',
  fun_type: couponType.FunType[0].value,
  use_type: couponType.UseType[0].value,
  prd_codes: [],
  prd_selected_infos: [],
  expiration_date: moment().format('YYYY-MM-DD HH:mm:ss'),
  applicable_days: 1,
  create_count: 1,
  available_count: 1,
  used_yn: 'N',
  create_date: '',
  memo: '',
};

export default {
  name: 'ProductCoupon',
  components: { Tooltip },
  // comments: { Tooltip },
  data() {
    return {
      product: null,
      prdOpts: [],
      prdCodeSelected: null,
      productInfos: [],
      searchOpts: [
        {value: 'name', text: '쿠폰명'},
        {value: 'gift_code', text: '쿠폰코드'},
        {value: 'use_type', text: '쿠폰타입'},
        {value: 'used_yn', text: '사용완료(Y/N)'},
      ],
      progCount: 0,
      totalCount: 0,
      perPage: 20,

      createViewVisible: false,

      rows: [],
      claimLogs: [],
      selectedIdx: null,
      selectedRow: null,
      fromDate: moment().format("YYYY-MM-01"),
      toDate: moment(this.fromDate).endOf("month").format("YYYY-MM-DD"),

      // fromDate: moment().subtract( 7,"days").format('YYYY-MM-DD'),
      // toDate: moment().format('YYYY-MM-DD'),
      /////////////////////////////////////////////////////
      searchWord: null,
      searchField: 'name',
      paramMap: {},
      couponByOne: '',

      cou: _cou,
      useTypeOpt: couponType.UseType,
      funTypeOpt: couponType.FunType,
      createCouponFields: [
        { key: 'name'               , label: '쿠폰명'},
        { key: 'fun_type'           , label: '쿠폰기능 종류'},
        { key: 'use_type'           , label: '쿠폰사용 목적'},
        { key: 'prd_codes'          , label: '지급 라이센스'},
        { key: 'applicable_days'    , label: '라이센스 제공(일)'},
        { key: 'expiration_date'    , label: '쿠폰만료일'        , formatter: v=>{ return moment(v).format('YYYY-MM-DD HH:mm:ss') }},
        { key: 'create_count'       , label: '쿠폰생성 개수'     , formatter: v=>{ return commify(v) }},
        { key: 'available_count'    , label: '사용가능횟수'      , formatter: v=>{ return commify(v) }},
        { key: 'used_yn'            , label: '사용완료'},
        { key: 'memo'               , label: '메모'},
      ],
      detailCouponFields: [
        { key: 'idx'                , label: '번호'},
        { key: 'name'               , label: '쿠폰명'},
        { key: 'gift_code'          , label: '쿠폰코드'},
        { key: 'fun_type'           , label: '쿠폰기능 종류'},
        { key: 'use_type'           , label: '쿠폰사용 목적'},
        { key: 'prd_codes'          , label: '지급 라이센스'},
        { key: 'applicable_days'    , label: '라이센스 제공(일)'},
        { key: 'expiration_date'    , label: '쿠폰만료일'        , formatter: v=>{ return moment(v).format('YYYY-MM-DD HH:mm:ss') }},
        { key: 'create_count'       , label: '쿠폰생성 개수'     , formatter: v=>{ return commify(v) }},
        { key: 'available_count'    , label: '사용가능횟수'      , formatter: v=>{ return commify(v) }},
        { key: 'used_yn'            , label: '사용완료'},
        { key: 'create_date'        , label: '쿠폰 생성일자'     , formatter: v=>{ return moment(v).format('YYYY-MM-DD HH:mm:ss') }},
        { key: 'memo'               , label: '메모'},
      ],
      baseURL: $baseURL,
      isUpdating: false,
      useTypeValues: [],
      tooltipText: `[판매(SALES) 선택 시 유의사항]<br>
                    - 사용목적을 판매(SALES)로 선택할 시 기존 라이센스가 있다면 기간이 연장됩니다.<br>
                    - 판매(SALES)는 라이센스 대량구매, 대량연장 등 특수목적에 따라 사용될 수 있습니다.<br>
                    - 그 외 목적은 라이센스 기간이 연장되지 않고 대체됩니다.`,
    }

  },
  async created() {
    try {
      couponType.UseType.forEach(ut => {
        this.useTypeValues.push(ut.value);
      });
      await this.getPrdCode();
      await this.getCouponList();
    } catch (err) {
      console.log(err);
    }
  },
  computed: {
    createCountStats() {
      return (this.cou.create_count.toString().length <= 5);
    },
    applicableDaysStats() {
      return (this.cou.applicable_days.toString().length <= 4);
    },
    usedCountStats() {
      return (this.cou.available_count.toString().length <= 10);
    },
    prdSelectedStats() {
      return (this.cou.prd_selected_infos.length >= 1);
    }
  },

  mounted() {
    // console.log("mounted---------------------ProductToken");
    // below is not work!
  },

  methods: {
    comma(v){
      return commify(v)
    },

    async findUseType(v){
      const index = couponType.UseType.findIndex(data => data.value === v);

      if (index === -1) {
        return null;
      }
      return couponType.UseType[index];
    },
    async findUseTypeIndex(v){
      return couponType.UseType.findIndex(data => data.value === v);
    },

    async getPrdCode() {
      this.prdOpts.push( {value: null, text: '라이센스 선택', disabled: true} );

      try {
        // const r = await apiCall('get', `/admin/api/product/info`);
        const r = await apiCall('get', `/admin/api/product/info/use`);
        this.productInfos = r.result;

        r.result.map(i => {
          this.prdOpts.push( {value: i.prd_code, text: i.disp_name} );
        });
      } catch (err) {
        console.log(err);
      }
    },

    async selectedPrd() {
      const idx = this.prdOpts.findIndex(opt => opt.value === this.prdCodeSelected);

      this.cou.prd_codes.push( this.prdOpts[idx].value );
      this.cou.prd_selected_infos.push( this.productInfos[idx-1] );

      this.cou.prd_codes = [...new Set(this.cou.prd_codes)];
      this.cou.prd_selected_infos = [...new Set(this.cou.prd_selected_infos)];
    },

    async deletedPrd(prdCode) {
      this.cou.prd_codes = this.cou.prd_codes.filter(i => i !== prdCode);
      this.cou.prd_selected_infos = this.cou.prd_selected_infos.filter(i => i.prd_code !== prdCode);
    },

    async getCouponList() {
      this.$refs['excelGrid'].clearFilter();

      if (this.searchWord !== null) this.paramMap[this.searchField] = this.searchWord.toUpperCase();

      const qry = qs.stringify(this.paramMap);
      // console.log("query-string ---------- ", qry);

      try {
        this.rows = [];
        const r = await apiCall('get', `/admin/api/coupon/list?${qry}`);
        // console.log("getCouponList r ---------- ", r);

        if (r.code === 200) {
          this.rows = r.result;
          await toastSync(this.$bvToast, `${this.rows.length}건 조회됨`, 'primary');
        } else {
          await toastSync(this.$bvToast, `에러: ${r.code} ${r.message}`, 'danger');
        }
      } catch (err) {
        console.log(err);
      }
      this.paramMap = {};
    },

    exportExcel() {
      const format = 'xlsx';
      const exportSelectedOnly = true;
      const filename = 'coupon-master-' + moment().format('YYYY-MM-DD');
      this.$refs["excelGrid"].exportTable(format, exportSelectedOnly, filename);
    },

    async selectCouponRow(idx, evt) {
      if (!evt) {
        // this.master = null;
        return;
      }

      console.log("this.$refs['excelGrid'].getSelectedRecords().count", this.$refs['excelGrid'].getSelectedRecords().count);
      if(this.$refs['excelGrid'].getSelectedRecords().count > 1) return;

      console.log("this.$refs['excelGrid'].getSelectedRecords()[0]", this.$refs['excelGrid'].getSelectedRecords()[0]);
      this.couponByOne = this.$refs['excelGrid'].getSelectedRecords()[0];
    },

    validateCell(val, oldVal, rec, field) {
      console.log("----------------validateCell-------------------");
      console.log("validateCell---value ---> ", val);
      console.log("validateCell---oldVal ---> ", oldVal);
      console.log("validateCell---rec ---> ", rec);
      console.log("validateCell---field ---> ", field);
      // console.log( "validateCell---",this.$refs['excelGrid'] );
      // console.log( "validateCell---",this.$refs['excelGrid'].selectRecord(0) );
    },

    async createCoupon(e) {
      e.preventDefault();

      if (!this.prdSelectedStats) {
        await alertWarn( this.$bvModal, `지급할 라이센스 종류가 선택되지 않았습니다.`, "라이센스 선택");
        return ;
      }

      try {
        const r = await apiCall('POST', `/admin/api/coupon/create`, this.cou);
        if (r.code === 200) {
          await this.getCouponList();
          await alertSuccess( this.$bvModal, `${r.result.affectedRows}개의 쿠폰이 생성되었습니다.`, '쿠폰 생성 완료' );
          await this.resetCouponData();
        }else{
          await alertWarn( this.$bvModal, `Message: ${r.message}`, "쿠폰 생성 실패");
        }
      } catch (err) {
        console.log(err);
      }
    },

    async updateCouponField(rec) {
      let rc = rec[0];
      console.log("rc ---------> ", rc);

      if (!rc.keys[0]) {
        console.log("no key");
        return;
      }
      if (rc.name === 'use_type') {
        // console.log("await this.findUseType(rc.newVal)", await this.findUseType(rc.newVal));
        if (await this.findUseType(rc.newVal) === null) {
          await alertWarn(this.$bvModal, `쿠폰타입은 '${this.useTypeValues.toString()}'에서만 선택이 가능합니다.`, '수정불가');
          return;
        }
      }

      const tIndex = this.$refs['excelGrid'].rowIndex[rc.$id];
      let row = this.$refs['excelGrid'].table[tIndex];

      console.log("row ---------> ", row);

      let r = null;

      try {
        let param = {};

        const idx = row.idx;
        const fieldName = rc.name;
        const newVal = rc.newVal;

        param.idx = idx;
        param.fieldName = fieldName;
        param.newVal = newVal;

        r = await apiCall("PUT", `/admin/api/coupon/update/${idx}`, param);

        const fieldIndex = this.createCouponFields.findIndex(field => field.key === fieldName);

        if( r.code===200 ) {
          await toastSync(this.$bvToast, `${this.createCouponFields[fieldIndex].label} 수정됨`, 'warning');
        }else{
          row[fieldName] = rc.oldVal;
          await toastSync( this.$bvToast, `${this.createCouponFields[fieldIndex].label} 수정 에러: ${r.message}`);
        }
        console.log(r);
      } catch (err) {
        console.log(err);
      }
    },

    async deleteCoupon(recs) {
      try {
        console.log("recs ----> ", recs );
        let params = this.$refs['excelGrid'].getSelectedRecords();
        console.log("params =======>", params);
        this.progCount = 0;
        this.totalCount = params.length;

        if (this.totalCount === 0) {
          await alertWarn(this.$bvModal, `삭제할 쿠폰이 선택되지 않았습니다.`, '삭제오류');
          return ;
        }

        const confirmMsg = `${this.totalCount} 개의 데이터를 삭제 합니다. 삭제된 데이터는 복구할수 없습니다. 진행 하시겠습니까?`;

        if (!(await alertConfirm(this.$bvModal, confirmMsg, '쿠폰삭제'))) {
          return;
        }

        // this.$refs['excelGrid'].deleteSelectedRecords();
        let failCnt = 0;
        for( let record of params){
          // console.log( "deleteRecord for --------->", record );
          const tIndex = this.$refs['excelGrid'].rowIndex[record.$id];
          const r = await apiCall('DELETE', `/admin/api/coupon/delete/${record.idx}`);

          if( r.code===200 ){
            this.progCount++;
            this.$refs["excelGrid"].deleteRecord(tIndex);
          }
        }

        await alertSync(this.$bvModal, {text: `삭제: ${this.progCount}건, 실패: ${failCnt}`});
        this.$refs['excelGrid'].clearAllSelected();
        await this.getCouponList();

      } catch (err) {

        console.log(err);
      }
    },

    // 쿠폰 생성 정보 초기화
    async resetCouponData() {
      this.cou = {
        idx: '',
        name: '',
        gift_code: '',
        fun_type: couponType.FunType[0].value,
        use_type: couponType.UseType[0].value,
        prd_codes: [],
        prd_selected_infos: [],
        expiration_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        applicable_days: 1,
        create_count: 1,
        available_count: 1,
        used_yn: 'N',
        create_date: '',
        memo: '',
      };

    },

    async onColumnResize() {
      console.log("onColumnResize 실행");
    }

  }
}
</script>

<style>
/*#systable > colgroup > col {
  width: 200px !important;
}*/
</style>