<!-- MultiOrder 진입 시 Tax(개인/사업자) 정보가 없을 경우 주문에 Tax를 설정하는 모달 폼 -->
<template>
  <div class="bg-white-gray px-5">
    <b-modal id="bv-modal-order-tax" 
      fade
      hide-footer 
      no-close-on-backdrop
      no-close-on-esc
      header-class="order-tax-header"
      dialog-class="order-tax-dialog"
      content-class="order-tax-content"
      body-class="order-tax-body"
      modal-class="order-tax-modal"
    >
      <template #modal-header="">
        <h3 style="font-weight: 600;">주문 필수정보</h3>
      </template>

      <div>
        <div class="mt-1" style="">
          <b-input-group class="mb-2" style="width: 20rem;">
            <b-input-group-prepend is-text> <BIconUiChecksGrid/> </b-input-group-prepend>
            <b-FormSelect required v-model="order.taxStat" :options="taxStatOpts" />
          </b-input-group>
        </div>

        <div v-show=" order.taxStat === '01' ">
          <!-- 사업자 정보 변경 -->
          <div style="margin: 2rem 0;">
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div style="text-align: left;">
                <h5>사업자 등록 정보</h5>
              </div>
              <div style="text-align: right;">
                <small class="">회원님의 사업자 정보를 확인합니다.</small>
              </div>
            </div>

            <hr style="margin-top:0.125rem;margin-bottom: 0.525rem;display: block;width: 100%;height: 1px;background-color: #63a3ff;opacity: 0.8;">

            <BFormGroup class="mt-3"
                        label-for="compNameInput">
              <BInputGroup id="compNameInput">
                <BInputGroupPrepend is-text>회사명</BInputGroupPrepend>
                <BFormInput v-model="business.compName" type="text" id="compName"/>
              </BInputGroup>
            </BFormGroup>

            <BFormGroup class="mt-3"
                        label-for="prNameInput">
              <BInputGroup id="prNameInput">
                <BInputGroupPrepend is-text>대표자명</BInputGroupPrepend>
                <BFormInput v-model="business.prName" type="text" id="prName"/>
              </BInputGroup>
            </BFormGroup>

            <BFormGroup class="mt-3"
                        label-for="bizKindInput">
              <BInputGroup id="bizKindInput">
                <BInputGroupPrepend is-text>업태·업종</BInputGroupPrepend>
                <BFormInput v-model="business.bizKind" type="text" id="bizKind"/>
              </BInputGroup>
            </BFormGroup>

            <BFormGroup class="mt-3"
                        label-for="compNoInput">
              <BInputGroup id="compNoInput">
                <BInputGroupPrepend is-text>사업자번호</BInputGroupPrepend>
                <BFormInput v-model="business.compNo" type="text" id="compNo" maxlength="10"  :state="business.compNo.toString().length === 10"/>
              </BInputGroup>
            </BFormGroup>

            <BFormGroup class="mt-3"
                        label-for="addressInput">
              <BInputGroup id="addressInput">
                <BInputGroupPrepend is-text>사업장주소</BInputGroupPrepend>
                <BFormInput v-model="business.address" type="text" id="address"/>
              </BInputGroup>
            </BFormGroup>
          </div>
        </div>

        <div class="mt-3" style="text-align: center;">
          <button class="btn-howcost" style="width: 50%;" @click="salesOrderUpdate">주문정보 저장</button>
        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { apiCall } from '../../../common/utils';

export default {
  name: 'MultiOrderTax',
  props: {
    order: {
      type: Object,
    }
  },
  data() {
    return {
      today: moment().add(9, 'hours'),
      taxStatOpts: [
        { value: null, text: '개인/사업자 선택' },
        { value: '03', text: '개인' },
        { value: '01', text: '사업자' },
      ],
      business: {
        compName: '', //회사명
        prName: '', //대표자명
        bizKind: '', //업태.업종
        compNo: '', //사업자번호
        address: '', //사업장주소
      },
    }
  },
  async created() {
    // console.log("this.order ==========> ", this.order);
    if (this.order.xcostUser.business) this.business = this.order.xcostUser.business;
  },
  computed: {

  },
  methods: {

    locationReload() {
      this.$bvModal.hide('bv-modal-order-tax');
      window.location.reload();
    },

    async salesOrderUpdate() {
      if (this.order.taxStat === '01') {
        if (!this.business.compName || this.business.compName.length < 1) return alert("회사명이 유효한지 확인해주세요.");
        if (!this.business.compNo || this.business.compNo.toString().length !== 10) return alert("사업자 번호가 유효한지 확인해주세요.");
      }

      try {
        this.order.business = this.business;
        const r = await apiCall('POST', `/api/order/tax/update`, {order: this.order});

        if (r.code === 200) {
          // alert("저장이 완료 되었습니다.");
          this.locationReload();
        }

      } catch (error) {
        console.error("create promise error: ", error);
      }
    }
  },
}
</script>

<style>
p {
  margin: 0;
}
.content-card {
  background-color: rgba(255, 255, 255) !important;
  border: solid 1px #eaeaea;
  border-radius: 4px;
  padding: 1em 2em;
}

.order-tax-header {
  align-items: center;
  padding: 1rem 0 0.5rem 0 !important;
}
.order-tax-dialog {
  margin-top: 120px !important;
  max-width: 700px;
}
.order-tax-content {
  padding: 1rem 2rem !important;
  color: #000000;
}
.order-tax-body {
  padding: 1rem 0 !important;
}
.order-tax-modal {
  transition: opacity 0.3s ease-in-out;
}
</style>
