import { render, staticRenderFns } from "./ProductCoupon.vue?vue&type=template&id=2db55c52"
import script from "./ProductCoupon.vue?vue&type=script&lang=js"
export * from "./ProductCoupon.vue?vue&type=script&lang=js"
import style0 from "./ProductCoupon.vue?vue&type=style&index=0&id=2db55c52&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports