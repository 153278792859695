<template>
  <div>
    <!-- Header-->
    <header class="bg-dark py-5">
      <div class="container px-4 px-lg-5 my-5">
        <div class="text-center text-white">
          <h1 class="display-4 fw-bolder nanum-square-bold">구독 서비스 가격</h1>
          <p class="lead fw-normal text-white-50 mb-0 nanum-square-bold">
            Xcost & Xpixels의 강력한 적산의 기술을 사용해보세요.
          </p>
        </div>
      </div>
    </header>
    
    <!-- Section-->
    <section class="py-4 bg-main">

      <div class="container px-4 px-lg-5 mt-3">
        <b-alert show variant="success">
          <h4 class="alert-heading mb-2" style="font-weight: bold;">구독 서비스 안내</h4>
          <p class="mb-0">저희 제품은 계정에 귀속되는 구독 상품이므로 결제하신 계정에서만 해당 상품을 이용하실 수 있습니다.</p>
          <p class="mb-0">Xcost 구독 라이선스 서비스는 기업/개인을 구분하지 않고 사용이 가능한 점 참고해주시기 바랍니다.</p>
          <hr>
          <div class="mb-0">
            <span class="mr-1">5인 이상의 단체로 구입하시려는 경우,</span>
            <b-icon icon="telephone-fill"></b-icon>
            <span class="mb-0 ml-1 noto-sans">support@howcost.co.kr 문의 부탁드립니다.</span>
          </div>
        </b-alert>
      </div>

      <div class="container px-4 px-lg-5 mt-5">

        <!-- 업그레이드 행사 제품 (단일구매 원칙) -->
        <div v-if="isUpgradeBenefit" class="mb-4">
          <div class="mb-4" style="display: flex; border-bottom: solid 1px white; justify-content: space-between; align-items: flex-end;">
            <h5 class="mb-2" style="color: white;">쉿!, {{ userInfo.nick }}님께만 제공되는 특별한 혜택을 소개해드릴게요!</h5>
          </div>
          <div class="gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-3" :class="{ 'row': !isMobile }">

            <div v-for="(upPrdInfo, index) in upgradeProducts" :key="index" class="col mb-5" style="max-width: 100%;">
              <div class="card h-100 m-add-size" style="min-width: 14rem;">
                <b-badge class="store-badge fc-w" variant="warning" style="font-size: 10pt;">업그레이드</b-badge>
                <img class="card-img-top" :src="$imgUrl + upPrdInfo.sale_file_nm" alt="..." style="height: 240px;" />
                <!-- <span>{{ upPrdInfo.prd_code }}</span> -->
                <div class="card-body bg-white" style="padding: 0.525rem 3rem 0;">
                  <h5 class="fw-bolder" style="font-weight: 600;">{{ upPrdInfo.disp_name }}</h5>

                  <div v-if="upPrdInfo.discount_yn === 'Y'">
                    <p class="mb-0" style="font-size: 10.6pt; color: #a3a3a3; text-decoration: line-through;">잔여기간 / {{ commify( upPrdInfo.unit_price ) }}원</p>
                    <p class="mb-0" style="font-size: 12pt; font-weight: 600;">잔여기간 / {{ commify(  Math.floor((upPrdInfo.unit_price - upPrdInfo.discount_price)) ) }}원</p>
                  </div>
                  <div v-else>
                    <p class="mb-0" style="font-size: 12pt; font-weight: 600;">잔여기간 / {{ commify(upPrdInfo.unit_price) }}원</p>
                  </div>

                  <p class="mt-2 mb-0" v-html="upPrdInfo.description"></p>
                </div>
                <div class="card-footer border-top-0 bg-white" style="padding: 0 1.5rem 1.5rem;">
                  <div class="text-center">
                    <button class="btn btn-outline-howcost mt-auto" @click="productUpgradeBuy(index)">업그레이드 구매</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 패키지 제품 (단일구매) -->
        <div class="mb-4" v-if="packageSalesInfos.length > 0">
          <div class="mb-4" style="display: flex; border-bottom: solid 1px white; justify-content: space-between; align-items: flex-end;">
            <h5 class="mb-2" style="color: white;">패키지 구독상품</h5>
          </div>
          <div class="gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-3" :class="{ 'row': !isMobile }">

            <div v-for="(packageInfo, index) in packageSalesInfos" :key="index" class="col mb-5" style="max-width: 100%;">
              <div class="card h-100 m-add-size store-package-instar-border" style="min-width: 14rem;">

                <b-badge v-if="packageInfo.discountRate > 0" class="store-package-instar-badge fc-w" >{{ packageInfo.discountRate }}% 할인</b-badge>

                <img class="card-img-top bg-white" :src="$imgUrl + packageInfo.image" alt="..." style="height: 240px;" />
                <div class="card-body bg-white" style="padding: 0.525rem 3rem 0;">
                  <h5 class="fw-bolder" style="font-weight: 600;">{{ packageInfo.title }}</h5>


                  <div v-if="packageInfo.discountAmount > 0 || packageInfo.discountRate > 0">
                    <p class="mb-0" style="font-size: 10.6pt; color: #a3a3a3; text-decoration: line-through;">1년 / {{ commify( packageInfo.price ) }}원</p>
                    <p class="mb-0" style="font-size: 12pt; font-weight: 600;">1년 / {{ commify( (packageInfo.amount) ) }}원</p>
                    <p class="mb-0" style="font-size: 10.6pt;">(월 {{ commify( Math.floor((packageInfo.amount / 12)) ) }}원)</p>
                  </div>
                  <div v-else>
                    <p class="mb-0" style="font-size: 12pt; font-weight: 600;">1년 / {{ commify(packageInfo.amount) }}원</p>
                  </div>

                  <p class="mt-2 mb-0" v-html="packageInfo.content"></p>
                </div>
                <div class="card-footer border-top-0 bg-white" style="padding: 0 1.5rem 1.5rem;">
                  <div class="text-center">
                    <div style="display: grid;" v-if="packageInfo.isAccountLimited || packageInfo.isQtyLimited">
                      <strong class="mt-auto" style="font-size: 12pt; color: #ff4141;">[ 매진 ]</strong>
                      <span class="mt-auto" style="font-size: 11pt; color: #ff4141;">구매 가능한 수량이 남아있지 않습니다.</span>
                    </div>
                    <button v-if="!packageInfo.isAccountLimited && !packageInfo.isQtyLimited"
                            class="btn btn-outline-howcost mt-auto"
                            @click="productPackageBuy(index)"
                    >패키지 구매</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 기본 제품 선택 폼 (여러 제품 선택 구매) -->
        <div class="mb-4" style="display: flex; border-bottom: solid 1px white; justify-content: space-between; align-items: flex-end;">
          <h5 class="mb-2" style="color: white;">개별 구독상품</h5>
          <b-button class="store-move-btn mb-2 px-4 p-2 fc-w" style="border-color: white; font-size: medium;" href="#/introduction">나에게 맞는 적산 솔루션 찾기</b-button>
        </div>
        <div class="gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center" :class="{ 'row': !isMobile }">
          <div v-for="(prdInfo, index) in productSalesInfos" :key="index" 
              class="col mb-5" style="max-width: 100%;">
            <div class="card h-100 m-add-size" style="align-items: center;min-width: 14rem;" :class="{ prdSelected: selectProducts.includes(index) }">

<!--              <b-badge v-if="prdInfo.prd_code === 'CP2C-0012' && prdInfo.discount_yn === 'N'" class="store-badge" variant="success">내역 끝판왕!</b-badge>-->
              <b-badge v-if="prdInfo.discount_yn === 'Y'" class="store-badge" variant="danger" style="font-size: 10pt;">할인중!!</b-badge>

              <img class="card-img-top" :src="$imgUrl + prdInfo.sale_file_nm" alt="..." style="width: 230px;" />

              <div class="card-body p-4 bg-white" style="padding-bottom: 0 !important;">
                <div class="text-center">
                  <h5 class="fw-bolder" style="font-weight: 600;">{{ prdInfo.disp_name }}</h5>

                  <!-- 제품별 가격 -->
                  <div v-if="prdInfo.discount_yn === 'Y'">
                    <p class="mb-0" style="font-size: 10.6pt; color: #a3a3a3; text-decoration: line-through;">1년 / {{ commify( prdInfo.unit_price ) }}원</p>
                    <p class="mb-0" style="font-size: 12pt; font-weight: 600;">1년 / {{ commify( (prdInfo.unit_price - prdInfo.discount_price) ) }}원</p>
                    <p class="mb-3">(월 {{ commify(Math.floor( (prdInfo.unit_price - prdInfo.discount_price)/12) ) }}원)</p>
                  </div>
                  <div v-else>
                    <p class="mb-0" style="font-size: 12pt; font-weight: 600;">1년 / {{ commify( prdInfo.unit_price ) }}원</p>
                    <p class="mb-3">(월 {{ commify( Math.floor( (prdInfo.unit_price / 12) ) ) }}원)</p>
                  </div>

                  <p class="mb-0" v-html="prdInfo.description"></p>
                </div>
              </div>
              <div class="card-footer border-top-0 bg-white" style="padding: 0 1.5rem 1.5rem;">
                <div class="text-center">
                  <button v-if="selectProducts.includes(index)" class="btn btn-outline-danger mt-auto" @click="toggleSelection(index)">선택취소</button>
                  <button v-else class="btn btn-outline-howcost mt-auto" @click="toggleSelection(index)">제품선택</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 제품선택 결제 폼 -->
        <form @submit="onSubmit">
          <div class="p-3 mb-5 noto-sans bg-snow-white" style="border: 1px solid #ededed; border-radius: 4px;">
            <div class="mb-3 pb-3" style="font-size: 12pt; border-bottom: 1px solid #d8dbe0;">
              <b-icon class="ml-1 mr-1" icon="basket-fill" />
              <span style="font-weight: 500;">제품선택 목록</span>
            </div>

            <div>
              <div v-if="items.length > 0" class="mt-4 mb-4">

                <b-table :items="items" :fields="fields">
                  <template #cell(unit_price)="data">
                    <span v-if="data.item.discount_yn === 'Y'">{{ commify( (data.item.unit_price - data.item.discount_price) )}}</span>
                    <span v-else>{{ commify( data.item.unit_price ) }}</span>
                  </template>
                </b-table>

              </div>

              <div v-else class="text-center pb-3">
                <span style="font-size: 12pt; font-weight: 400;">선택한 제품이 없습니다.</span>
              </div>
            </div>

            <div class="text-right pt-1" style="border-top: 1px solid #d8dbe0;">

              <div class="mt-3 pr-3">
                <span class="mr-2" style="font-size: 11pt; font-weight: 500;">총 상품금액</span>
                <span style="color: #d40022; font-size: 14pt; font-weight: 600;">{{ commify(totalPrice) }}원</span>
              </div>

<!--          <div class="mt-3" style="text-align: -webkit-right;">
                <b-input-group class="mb-2" style="width: 15rem;">
                  <b-input-group-prepend is-text> <BIconUiChecksGrid/> </b-input-group-prepend>
                  <b-FormSelect required v-model="order.tax_stat" :options="taxStatOpts" @change="taxStatCheck()" />
                </b-input-group>
              </div>

              <div style="text-align: -webkit-right;">
                <b-collapse id="compInfo" v-model="taxStatView" style="width: 30rem;">
                  <b-card class="m-0" border-variant="howcost" header="howcost" header-bg-variant="howcost" header-text-variant="white" >

                    <div slot="header" class="row pl-2 pr-2" style="justify-content: space-between;">
                      <div>
                        <strong>사업자 등록 정보</strong>
                      </div>
                      <div class="card-header-actions">
                        <small>계산서 발행을 위해 정확하게 기재해 주시기 바랍니다.</small>
                      </div>
                    </div>

                    <b-input-group size="sm" class="mb-2">
                      <b-input-group-prepend is-text><BIconCreditCard2Front/></b-input-group-prepend>
                      <b-FormInput v-model="order.comp_name" type="text" placeholder="회사명/상호" autocomplete="comp_name" :required=" order.tax_stat==='01' "/>
                    </b-input-group>

                    <b-input-group size="sm" class="mb-2">
                      <b-input-group-prepend is-text><BIconCreditCard2Front/></b-input-group-prepend>
                      <b-FormInput v-model="order.pr_name" type="text" placeholder="대표자명" autocomplete="pr_name" :required=" order.tax_stat==='01' "/>
                    </b-input-group>

                    <b-input-group size="sm" class="mb-2">
                      <b-input-group-prepend is-text><BIconCardList/></b-input-group-prepend>
                      <b-FormInput v-model="order.biz_kind" type="text" placeholder="업태/업종 입력 예) 건설업/실내건축공사업" autocomplete="biz_kind" :required=" order.tax_stat==='01' "/>
                    </b-input-group>

                    <b-input-group size="sm" class="mb-2">
                      <b-input-group-prepend is-text> <BIconBuilding/> </b-input-group-prepend>
                      <b-FormInput v-model="order.comp_no" type="text" maxlength="10" placeholder="사업자 번호" autocomplete="comp_no" :required=" order.tax_stat==='01' "/>
                    </b-input-group>

                    <b-input-group size="sm" class="mb-2">
                      <b-input-group-prepend is-text><BIconMap/></b-input-group-prepend>
                      <b-FormInput v-model="order.address" placeholder="사업장 소재지 (주소)" autocomplete="address" :required=" order.tax_stat==='01' "/>
                    </b-input-group>

                    <small>
                      사업자 정보가 자동으로 나오지 않나요?
                      사업자 정보를 <a href="#/service/member-password-verify" target="_blank">등록</a>해보세요!
                    </small>
                  </b-card>
                </b-collapse>
              </div>-->

              <div class="mt-3 mb-1">
                <b-button class="btn-howcost" style="width: 16rem; border-radius: 5px;" type="submit">
                  <b-icon class="mr-1" icon="credit-card-fill" />
                  구매하기
                </b-button>
              </div>
            </div>

          </div>
        </form>

      </div>
    </section>
  </div>
</template>

<script>
import {
  alertModal,
  apiCall,
  cloneVar,
  confirmModal,
  checkBisNo,
  alertConfirm,
  alertSync,
  alertWarn,
  alertSuccess,
  apiMultiPart,
  commify,
  momentDate,
  alertPageMove,
  alertError,
  warpSite, isMobileScreen,
} from '../../common/utils';

  import moment from "moment";
  import axios from 'axios';

const _order = {
  // prd_code     : null,
  sales_code   : 'SINGLE-ALL', //판매코드(전체제품목록)
  sales_master : null,
  products     : [],
  // dept_code    : null,
  unit_price   : null,
  qty          : 1,
  warranty_mon : null,
  price        : null,
  pay_amt      : null,
  // pay_type     : null,
  cu_seq       : null, //회원번호
  cu_id        : null, //회원아이디
  email        : '', //회원이메일
  name         : null, //회원이름
  phone_no     : null, //회원연락처
  comp_no      : null, //사업자번호
  comp_name    : null, //회사명
  pr_name      : null, //대표자명
  address      : null, //사업소재지
  zip_code     : null,
  // pay_stat     : null,
  // pay_dt       : null,
  tax_stat     : null, // {01:사업자, 03:개인}
  // tax_dt       : null,
  // tax_seq      : null,
  cncl_desc    : null,
  biz_kind     : null, //업태&업종
  description  : '', //요청사항 (비활성화)
  orderToken   : '', //주문Token
};

export default {
  name: "Store",
  props: ['memberToken'],
  data() {
    return {
      productSalesInfos: [],
      selectProducts: [],
      packageSalesInfos: [],
      fields: [
        {
          key: "disp_name",
          label: "제품명"
        },
        {
          key: "use_yn", //key활용
          label: "수량",
          formatter: (value) => {
            return `1개`;
          }
        },
        {
          key: "warranty_mon",
          label: "기간",
          formatter: (value) => {
            return `${value}개월`;
          }
        },
        {
          key: "unit_price",
          label: "금액",
          formatter: (value) => {
            return `${this.commify(value)}원`;
          }
        }
      ],
      items: [],
      totalPrice: 0,
      discountPrice: 0,
      order: cloneVar(_order),
      taxStatOpts: [
        { value: null, text: '개인/사업자 선택' },
        { value: '03', text: '개인' },
        { value: '01', text: '사업자' },
      ],
      isLogin: false,
      userLicense: null,
      isUpgradeBenefit: false,
      upgradeProducts: [],
      salesInfo: null,
      taxStatView: false,
      token: '',
      isMobile: false,
    };
  },
  watch: {
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  },

  async created() {
    try {
      await this.productInfo();

      const r = await apiCall('GET', `/user/auth/get-token`);
      this.token = r.result;

      if (this.isAuth) {
        await this.loadUserLicense();
        await this.loadBusinessInfo();
      }
      await this.packageProductInfo();
    } catch (e) {
      console.error(e);
    }
  },
  computed: {
    user() { return this.$store.state.userStore.user; },
    isAuth() { return this.$store.state.userStore.isAuth; },
    compnoState: function(){
      return checkBisNo(this.order.comp_no);
    },
  },
  methods: {
    commify(num) { return commify(num) },
    taxStatCheck() { this.taxStatView = (this.order.tax_stat === '01') },
    checkScreenSize() { this.isMobile = isMobileScreen() },

    async loadUserLicense() {
      try {
        axios.defaults.headers.common['Authorization'] = this.token;
        const r = await apiCall('POST', `/api/member/license`, {userInfo: this.user});
        if (r.code === 200) {
          this.userLicense = r.result;
          // console.log( "license ========> ", this.userLicense);
          await this.upgradeBenefit();
        } else {
          throw new Error("user license load failed..");
        }
      } catch (error) {
        console.error("loadUserLicense ERROR: ", error);
      }
    },

    async upgradeBenefit() {
      try {
        const today = moment().add(9, 'hours');
        const r = await apiCall('GET', `/api/product/upgrade-product`);
        if (r.code === 200) {
          const upPrds = r.result;
          // console.log( "upPrds ========> ", upPrds);
          // console.log( "license ========> ", this.userLicense);
          for (let license of this.userLicense) {
            upPrds.forEach(item => {
              if (item.upg_bef_prd_code === license.prdCode && item.pub_dt > license.updatedAt && moment(license.lvEndDt).diff(today, 'days') < 365) {
                this.upgradeProducts.push(item);
                this.isUpgradeBenefit = true;
              }
            });
          }
        } else {
          throw new Error("upgradeBenefit respons code Error");
        }
      } catch (error) {
        console.error(error);
      }
    },

    toggleSelection(index) {
      const selectedIndex = this.selectProducts.indexOf(index);

      if (selectedIndex === -1) {
        this.selectProducts.push(index);
      } else {
        this.selectProducts.splice(selectedIndex, 1);
      }
      this.selectionProductInfo();
      // console.log("this.selectProducts =============> ", this.selectProducts);
    },

    selectionProductInfo() {
      const selectIndex = this.selectProducts;
      this.items = [];
      selectIndex.forEach( (idx) => {
        this.items.push(this.productSalesInfos[idx]);
      });

      this.totalPrice = 0;
      this.discountPrice = 0;
      this.items.forEach( (item) => {
        this.discountPrice += (item.discount_yn === 'Y') ? item.discount_price : 0;
        this.totalPrice += (item.discount_yn === 'Y') ? (item.unit_price - item.discount_price) : item.unit_price;
      })
      // console.log("this.items =============> ", this.items);
    },

    async productInfo() {
      const r = await apiCall('GET', `/api/product/sale-product`);

      if (r.code === 200) {
        this.productSalesInfos = r.result;
      } else {
        console.error(r.message);
      }
    },

    async packageProductInfo() {
      try {
        const r = await apiCall('GET', `/api/product/package-product`);

        if (r.code === 200) {
          // console.log("packageProductInfo =====> ", r.result);
          this.packageSalesInfos = r.result;
        } else {
          console.error(r.message);
        }
      } catch (e) {
        console.error(e);
      }
    },

    async onSubmit(evt) {
      evt.preventDefault();

      try {
        if (!this.token) {
          if (confirm("로그인이 필요한 서비스입니다. 로그인 하시겠습니까?")) {
            window.location.href = warpSite("service/login");
          }
          return;
        }
        if (this.selectProducts.length === 0) {
          alert("제품을 선택하지 않으셨습니다. 제품을 선택해주세요.");
          return;
        }

        await this.salesMasterInfo();

        // console.log("this.user ========> ", this.user);
        this.order.cu_id = this.user.id;
        this.order.cu_seq = this.user.no;
        this.order.email = this.user.email;
        this.order.name = this.user.name;
        this.order.phone_no = this.user.mobile;

        // console.log("this.items -----> ", this.items);
        this.order.products = this.items;
        this.order.sales_master = this.salesInfo._id;

        // console.log( " this.order ===========> ", this.order);

        axios.defaults.headers.common['Authorization'] = this.token;
        const r = await apiCall('post', `/api/sales/new/confirm`, this.order);
        // console.log( "onSubmit ----------------> result: ", r );
        if(r.code===200){
          const orderToken = r.result.orderToken;
          // await this.$router.push( {name:"MultiOrderDetail", params: {orderToken: orderToken} } );
          const routerMove = this.$router.resolve( {name:"MultiOrderDetail", params: {orderToken: orderToken} } );
          window.open(routerMove.href, '_blank');
        }else{
          await alertError(this.$bvModal, `[ERROR] ${r.message}`);
        }
        location.reload();
      } catch (error) {
        console.error( error );
        alert(error.message);
      }
    },

    async productUpgradeBuy(idx) {
      try {
        if (!this.token) {
          if (confirm("로그인이 필요한 서비스입니다. 로그인 하시겠습니까?")) {
            window.location.href = warpSite("service/login");
          }
          return;
        }

        await this.salesMasterInfo();

        this.order.cu_id = this.user.id;
        this.order.cu_seq = this.user.no;
        this.order.email = this.user.email;
        this.order.name = this.user.name;
        this.order.phone_no = this.user.mobile;

        this.order.products.push(this.upgradeProducts[idx]);
        this.order.sales_master = this.salesInfo._id;

        // console.log( " this.order ===========> ", this.order);
        // console.log( " this.order ===========> ", this.order.products);

        axios.defaults.headers.common['Authorization'] = this.token;
        const r = await apiCall('post', `/api/sales/new/confirm`, this.order);
        // console.log( "onSubmit ----------------> result: ", r );
        if(r.code===200){
          const orderToken = r.result.orderToken;
          // await this.$router.push( {name:"MultiOrderDetail", params: {orderToken: orderToken} } );
          const routerMove = this.$router.resolve( {name:"MultiOrderDetail", params: {orderToken: orderToken} } );
          window.open(routerMove.href, '_blank');
        }else{
          await alertError(this.$bvModal, `[ERROR] ${r.message}`);
        }
        location.reload();
      } catch (error) {
        console.error( error );
        alert(error.message);
      }
    },

    async productPackageBuy(idx) {
      try {
        if (!this.token) {
          if (confirm("로그인이 필요한 서비스입니다. 로그인 하시겠습니까?")) {
            window.location.href = warpSite("service/login");
          }
          return;
        }
        const packageOrder = this.packageSalesInfos[idx];

        this.order.sales_code = packageOrder.code;
        this.order.products = packageOrder.products;
        this.order.sales_master = packageOrder._id;

        const r = await apiCall('post', `/api/sales/package/confirm`, this.order);

        // console.log('idx ======> ', idx);
        // console.log('r ======> ', r);

        if(r.code===200){
          const orderToken = r.result.orderToken;
          const routerMove = this.$router.resolve( {name:"MultiOrderDetail", params: {orderToken: orderToken} } );
          window.open(routerMove.href, '_blank');
        }else{
          await alertError(this.$bvModal, `[ERROR] ${r.message}`);
        }
        location.reload();
      } catch (error) {
        console.error( error );
        alert(error.message);
      }
    },

    async salesMasterInfo() {
      try {
        const r = await apiCall('GET', `/api/sales/master/${this.order.sales_code}`);
        if (r.code === 200) this.salesInfo = r.result;
      } catch (error) {
        console.error( error );
      }
    },

    async loadBusinessInfo() {
      try {
        const r = await apiCall('POST', `/api/member/business-info`);
        if (r.code === 200 && r.result) {
          this.order.comp_no = r.result.compNo;
          this.order.comp_name = r.result.compName;
          this.order.pr_name = r.result.prName;
          this.order.address = r.result.address;
          this.order.biz_kind = r.result.bizKind;
        }
      } catch (error) {
        console.error( error );
      }

    }


  },
};
</script>
